import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClientJobsRead, SearchJobs, GetExpertPreviewLoad } from "../utils/apiCalls";
import PageWrapper from "../components/PageWrapper";
import Sidebar from "../components/Sidebar/Sidebar"; 
import JobSearchgrid from "../sections/search/JobSearchgrid";
import JobSearchlist from "../sections/search/JobSearchlist";
import { Select } from "../components/Core";
import GlobalContext from "../context/GlobalContext";
import { Switch } from "../components/Core";
import Icon from '@mui/material/Icon';
import { Helmet } from "react-helmet";
  

export default function SearchGrid(props) {
    const { KEY } = useParams();
    const gContext = useContext(GlobalContext);

    const [values, setValues] = React.useState({ countryId: null, country: null, keyword: "", jobTypes: "", expLvlIds: '', skillIds: '', complianceCategoryIds: '', complianceStandardIds: '', weeklyHrsIds: '', projLengthIds: '', period: '', rateRange: '', industryIds: "", dateSince: '', rateRangeFrom: '3', rateRangeTo: '5000', rateType: '' });
    const [showList, setShowList] = useState(true);
    const [alljobs, setAllJobs] = useState([]);
    const [job, setJobs] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [country, setCountry] = useState("");
    const [showMore, setShowMore] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showProfileFilter, setShowProfileFilter] = useState(false);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [searchHistory, setSearchHistory] = useState(localStorage.getItem('jobhistory'));

    const [pageCount, setPageCount] = useState(1);
    const [clear, setClear] = useState(false);
    const user = localStorage.getItem('connect');

   
    const handleChange = (e, name) => {
        const value = e;
        switch (name) {
            case "country":
                setValues({ ...values, ["country"]: value.value, ["countryId"]: value });
                break;
            case "keyword":
                var regex = new RegExp('[^0-9a-zA-Z-,_.\:\s ]+');

                if (!regex.test(e.target.value)) {
                    setValues({ ...values, ["keyword"]: e.target.value });

                    if (localStorage.getItem("jobSearch"))
                        localStorage.removeItem("jobSearch");
                }
                break;
            default:
                break;
        }
    }
    function SetSearchCriteria(field, value, dataObject) {
        if (field)
            setValues({ ...values, [field]: value });
        else
            setValues({ ...values, ...dataObject });
    }

    function clearFilterData(mode) {
        let filterValue = (mode == "side") ? {
            ...values, ["skillIds"]: '', ["dateSince"]: '', ["industryIds"]: '' ,["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '10', ["rateRangeTo"]: '500000', ["rateType"]: ''
        } : { ...values, ["skillIds"]: '', ["dateSince"]: '', ["industryIds"]: '' };
        setValues(filterValue);
        setShowProfileFilter(false);
    }
    function clearFilter() {
        localStorage.removeItem("jobSearch");
        let filterValue = {
            ["country"]: null, ["countryId"]: null, ["skillIds"]: '', ["keyword"]: '', ["dateSince"]: '', ["industryIds"]: '',
            ["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '10', ["rateRangeTo"]: '500000', ["rateType"]: ''
        };
       
        setValues(filterValue);
        setClear(true);
        BindJobs(filterValue);
       
    }
    function LoadMore() {
        setPageCount(pageCount + 1);
    }

    

    useEffect(() => {

        document.title = "Job Search | Syncuppro";
         var regex = new RegExp('[^0-9a-zA-Z-,_.\:\s ]+');

        if (KEY && !regex.test(KEY)) {
            setValues({ ...values, ["keyword"]: KEY });

            removeHistory(keyword);
            BindJobs({ ...values, ["keyword"]: KEY });
        } else { BindJobs({ ...values }); }
        
           
        

    }, []);
    async function GetUserData() {
        let industryId = "";
        let data = null;
        setShowMore(false);
        setClear(false);
        if (gContext.userInfo.userType == "1") {
            try {
               
                const location = gContext.userLocation;
                if (gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length >0) {
                    let profile = gContext.userInfo.profileInfo;                    
                    data = { ["skillIds"]: '', ["keyword"]: "", ["dateSince"]: '', ["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '', ["rateRangeTo"]: '', ["rateType"]: '', ["avgRating"]: "", ["industryIds"]: industryId, ["expLvlIds"]: profile[0].explevelid.toString(), ["skillIds"]: profile[0].skillids, ["complianceCategoryIds"]: profile[0].compcatids, ["complianceStandardIds"]: profile[0].complianceids, ["weeklyHrsIds"]: profile[0].weeklyhoursid.toString(), ["skillIds"]: profile[0].skillids, ["industryIds"]: (profile[0].indtypeidslist) ? profile[0].indtypeidslist : '', ["countryId"]: location, ["country"]: location.value, ["keyword"]: '' };
                }
            }
            catch (e) {
               console.log(e);

            }
            setShowAdvanceSearch(true);
        }
        else if (gContext.userInfo.userType == "2") {

            const profile = (gContext.userInfo.profileInfo) ? JSON.parse(gContext.userInfo.profileInfo) : null;
            if (profile && profile.length > 0) {
                industryId = profile[0].indtypeids;

            }

            data = { ["skillIds"]: '', ["keyword"]: "", ["dateSince"]: '', ["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '', ["rateRangeTo"]: '', ["rateType"]: '', ["avgRating"]: "", ["industryIds"]: industryId, ["countryId"]: null, ["country"]: null };
        }


        const location = gContext.userLocation;
        if (data) {
            setValues({ ...data, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null, ["keyword"]: '' });
            BindJobs({ ...data, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null, ["keyword"]: '' });
        }
        else {
            setValues({ ...values, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null, ["keyword"]: '' });
            BindJobs({ ...values, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null, ["keyword"]: '' });
        }
        setPageCount(1);

    }
    useEffect(() => {

        document.body.classList.add('fix-inner-block');

    }, [ ]);
    useEffect(() => {

        if (pageCount > 1) {
            let data = alljobs;
            setJobs(data.slice(0, (data.length > (pageCount * 10)) ? (pageCount * 10) : data.length));
            setShowMore(data.length > (pageCount * 10));
        }

    }, [pageCount]);

    function BindKeyword(keyword) {

        setValues({ ...values, "keyword": keyword });
        BindJobs({ ...values, "keyword": keyword });
    }

    function removeHistory(keyword) {
        if (localStorage.getItem('jobhistory')) {
            let keys = localStorage.getItem('jobhistory').split("|").filter((item) => item.toLowerCase() != keyword.toLowerCase());
            let data = "";
            keys.map((item, index) => data += (keys.length == index + 1) ? item : item + "|");

            localStorage.setItem('jobhistory', data);
            setSearchHistory(data);
        }

    }
    async function BindJobs(job) {
        try {
            setLoaded(false);
            setCountry(""); 
            const result = (job) ? await SearchJobs(job) : await SearchJobs(values);

            if (result.data.searchJobs && result.data.searchJobs.responseData.code == "200") {

                let data = JSON.parse(result.data.searchJobs.responseData.message);

                if (data.length > 0) {
                    setAllJobs(data);
                    setJobs(data.slice(0, (data.length > (pageCount * 10)) ? (pageCount * 10) : data.length));
                    setShowMore(data.length > (pageCount * 10));
                }
                else {
                    setJobs([]);
                    setAllJobs([]);
                    setShowMore(false);
                }

            } else {
                setJobs([]);
                setAllJobs([]);
                setShowMore(false);
            }
            setKeyword((job) ? job.keyword : values.keyword);
            setCountry(values.countryId ? values.countryId.label : "");
            setTimeout(() => {
                gContext.setLoaderModalVisible(false);
                setLoaded(true);
            }, 1000);
            
            if (job.keyword.trim() != "") {
                if (localStorage.getItem('jobhistory')) {
                    let keys = localStorage.getItem('jobhistory').split("|").filter((item) => item.toLowerCase() == job.keyword.toLowerCase());

                    if (keys.length == 0) {
                        localStorage.setItem('jobhistory', localStorage.getItem('jobhistory') + "|" + job.keyword);
                        setSearchHistory(localStorage.getItem('jobhistory'));
                    }
                }
                else {
                    localStorage.setItem('jobhistory', job.keyword);
                    setSearchHistory(job.keyword);
                }

            }
            setValues({ ...values, ["keyword"]: "", ["country"]: null, ["countryId"]: null });
        }
        catch (e) {
            console.log(e);
            setJobs([]);
            setAllJobs([]);
            setShowMore(false);
            setLoaded(true);
            gContext.setLoaderModalVisible(false);
        }
    }

    function ApplySearch(filter, bind) {
        setClear(false);
        BindJobs(values);
    }

    function handleKeypress(e) {
        //it triggers by pressing the enter key 
        if (e.charCode === 13) {
            setPageCount(1);
            BindJobs(values);
        }
    }
    return (
        <>
            <Helmet>
                <title>Legal Compliance Analyst Manager Jobs | Syncuppro</title>
                <meta name="description" content="Find top compliance analyst, compliance manager, legal compliance, and risk and compliance jobs. Advance your career in legal, and risk management fields." />
                <link rel="canonical" href={window.location.origin + "/searchjob"} />
                  </Helmet>
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}  >


                <span className="screen-darken"></span>

                <div className="page-wrapper">
                    

                        <Sidebar ApplySearch={ApplySearch} ClearFilter={clear} filterData={values} SetSearchCriteria={SetSearchCriteria} clearFilterData={clearFilterData} showFilter={showFilter} setShowFilter={() => { setShowFilter(!showFilter) }}/>
                    <div className="pg-content-block">

                        <div className="search-group">
                            <div className="search-info p-relative">
                                <input type="text" className="form-control" id="search-input" name="keyword" defaultValue={values.keyword} value={values.keyword}
                                    placeholder="Keyword" onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} onKeyPress={(e) => (handleKeypress(e))} />
                                <Icon className="text-theme input-m-icon material-icons m-t-5">search</Icon>
                                <div className="divider"></div>
                                <Icon className="m-0 text-theme m-icon material-icons">location_on</Icon>
                                <Select name="country" options={gContext.countriesList} placeholder="Select..." value={values.countryId}
                                    className="form-control line" 
                                    border={false} onChange={(e) => handleChange(e, 'country')}
                                />
                                <button className="btn btn-orange search-btn" type="button" onClick={() => { setPageCount(1); BindJobs(values) }}><Icon className="text-white m-icon material-icons">search</Icon><span className="text-none">Search</span></button>
                            </div>
                        </div>
            
                        <div className="btn-search-group">

                            {
                                searchHistory && searchHistory.length > 0 && searchHistory.split('|').map((item, index) => {

                                    return (

                                        <span key={index} className="btn btn-orange btn-sm pointer" >  <button title="Click to view" onClick={() => BindKeyword(item)} data-toggle="tooltip" className="btn-icon text-white"> {item}</button> <button title="Click to remove" data-toggle="tooltip" className="btn-icon text-white m-l-5 m-icon material-icons" onClick={() => removeHistory(item)}>close</button></span>



                                    )
                                })}


                        </div>

                         

                        {user && <div className="form-check form-switch m-b-30">


                            <label className="form-check-label" for="mySwitch">{!showProfileFilter ? "Switch to view filtered jobs" : "Switch to view all jobs"}</label>
                            <input className="form-check-input" type="checkbox" id="mySwitch" onClick={(e) => { (showProfileFilter) ? clearFilter() : GetUserData(); setShowProfileFilter(!showProfileFilter); }} checked={showProfileFilter } />
                        </div>}
                        <div className="exports-group job-details-list">
                            {loaded && <div className="job-available-block">
                                <h6>{alljobs.length} Jobs available  {(keyword.trim() != "") ? " for '" + keyword + "'" : ""} {country ? " - " + country : ""}</h6>

                                <div className="desktop-hide">
                                    <button type="button" className="btn btn-dark-outline btn-sm collapsed" data-bs-toggle="collapse" data-bs-target="#sidebar-menu">
                                        <Icon className="m-icon material-icons m-r-5">filter_alt</Icon>Filter
                                    </button>
                                </div>

                                <ul className="tab-wrapper nav nav-tabs m-b-0" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <a className="btn tab-btn active" data-bs-toggle="tab" href="#list" aria-selected="false" role="tab" tabindex="-1" onClick={() => setShowList(true)}><Icon className="m-icon material-icons">list</Icon></a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="btn tab-btn grid-btn" data-bs-toggle="tab" href="#grid-view" aria-selected="true" role="tab" onClick={() => setShowList(false)}><Icon className="m-icon material-icons">grid_view</Icon></a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={`btn btn-dark-outline btn-sm  filter-btn pointer ${showFilter ? "" : "collapsed"}`} onClick={() => { setShowFilter(!showFilter); }}>
                                            <span class="m-icon material-icons m-r-5">filter_alt</span>
                                        </a>
                                    </li>
                                </ul>

                            </div>}
                            {!loaded &&
                                <><div className="row" >
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card list-block">
                                            <div className="expert-info-details">

                                                <div className="expert-data">
                                                    <div className="expert-profile m-0 align-items-start">
                                                        <div className="expert-img w-70 movingLoader p-20">
                                                            &nbsp;

                                                        </div>


                                                    </div>


                                                </div>


                                                <div className="work-pay movingLoader w-50">
                                                    &nbsp;
                                                </div>

                                            </div>




                                            <div className="type-name movingLoader w-100 ">
                                                &nbsp;


                                            </div>

                                            <p className="text-gray three-line   movingLoader w-full m-t-10 ">
                                                &nbsp;
                                                <p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p><p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p>
                                            </p>

                                            <div className="badge-group">

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                                     <div className="row" >
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card list-block">
                                            <div className="expert-info-details">

                                                <div className="expert-data">
                                                    <div className="expert-profile m-0 align-items-start">
                                                        <div className="expert-img w-70 movingLoader p-20">
                                                            &nbsp;

                                                        </div>

                                                         
                                                    </div>


                                                </div>


                                                <div className="work-pay movingLoader w-50">
                                                    &nbsp;
                                                </div>

                                            </div>




                                            <div className="type-name movingLoader w-100 ">
                                                &nbsp;


                                            </div>

                                            <p className="text-gray three-line   movingLoader w-full m-t-10 ">
                                                &nbsp;
                                                <p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p><p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p>
                                            </p>

                                            <div className="badge-group">

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                            </div>




                                        </div>
                                    </div>
                                </div></>}
                            {loaded && <div className="tab-content">
                                <div className={`tab-pane  ${!showList ? 'active show' : ''}`} role="tabpanel"> <JobSearchgrid jobsList={job} /></div>
                                <div className={`tab-pane ${showList ? 'active show' : ''}`} role="tabpanel"><JobSearchlist jobsList={job} /></div>




                            </div>}
                            <div className="load-more">
                                <button type="button" disabled={alljobs.length == job.length} className="btn btn-orange-outline d-flex align-item-center" onClick={() => { LoadMore(); }}><Icon className="m-icon material-icons m-r-10">refresh</Icon>Load more</button>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25  d-none" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-3 col-xs-8">
                                
                            </div>

                            <div className="col-12 col-md-9 col-xs-12 ">
                                
                                <form className="search-form search-2-adjustment ml-lg-0 ml-md-15"  >
                                    <div className="filter-search-form-2 bg-white rounded-sm shadow-7 pr-6 py-0  ">
                                        <div className="filter-inputs">
                                            <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                                                <input
                                                    className="form-control focus-reset pl-13 font-size-4"
                                                    type="text"
                                                    id="keyword"
                                                    name="keyword" defaultValue={values.keyword} value={values.keyword}
                                                    placeholder="Keyword" onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} onKeyPress={(e) => (handleKeypress(e))}
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                             
                                            <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                                                <Select name="country" options={gContext.countriesList} placeholder="Select..." value={values.countryId}
                                                    className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100 "
                                                    border={false} onChange={(e) => handleChange(e, 'country')}
                                                />

                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                            
                                        </div>
                                        <div className="button-block position-relative  pt-4">
                                            <button type="button"
                                                className="btn btn-primary btn-small line-height-reset   text-uppercase rounded-5 "
                                                style={{ minWidth: '100px', height: '40px' }}
                                                onClick={() => { setPageCount(1); BindJobs(values) }}>
                                                Search
                      </button>
                                        </div>

                                    </div>
                                </form>
                                <div className="col-lg-12 col-md-12 col-xs-12 text-left pt-5">
                                    <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">

                                        {
                                            searchHistory && searchHistory.length > 0 && searchHistory.split('|').map((item, index) => {

                                                return (<li key={index} className=" position-relative">

                                                    <a title="Click to search" data-toggle="tooltip" className="bg-green text-white  mr-6 px-7 py-0  font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer " onClick={() => BindKeyword(item)}>
                                                        {item}


                                                    </a>
                                                    <i title="Click to remove" data-toggle="tooltip" className="fas fa-times text-black-2 pos-abs-tr   mr-6  p-1  font-size-4 pointer " onClick={() => removeHistory(item)}></i>

                                                </li>)
                                            })}
                                    </ul>

                                </div>
                                {user && <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 text-left pt-10 pb-5 my-auto media px-0 mx-0  "> <span className="mb-n5  px-0 mx-0">{!showProfileFilter ? "Switch to view filtered jobs" : "Switch to view all jobs"} </span>  <Switch className="" onClick={(e) => { (showProfileFilter) ? clearFilter() : GetUserData(); setShowProfileFilter(!showProfileFilter); }} active={showProfileFilter} /></div>}
                                {/*{/*<!--
                                <div className="col-lg-12 col-md-12 col-xs-12 text-right pt-5"> <a onClick={() => { setShowAdvanceSearch(!showAdvanceSearch); }} className="pointer text-green-2 font-size-3  rounded-3 px-2   hover-shadow-1">
                                    <i className="fa fa-filter mr-2 font-weight-bold"></i>{" "}
                                    {showAdvanceSearch ? "Hide" :"Show"}  advanced filter
                                </a></div>
                                { showAdvanceSearch && <div className="col-12 col-md-12 col-xs-12 border-2 border round-10 py-5">
                                    <AdvanceSearch ApplySearch={ApplyAdvancedSearch} ClearFilter={clear} filterData={values} SetSearchCriteria={SetSearchCriteria} clearFilterData={clearFilterData}/>
                                </div>}
                                <div className="col-lg-12 col-md-12 col-xs-12 text-left pt-5 px-0">  

                                    We are showing jobs which might interest you, to see more unfiltered jobs click <a data-toggle="tooltip" title="Click here to view unfiltered jobs. " className="text-size-4 font-weight-semibold text-green-2 pointer" onClick={() => { clearFilter(); }}>here</a>.
                                    
                               </div>
                               -->*/}*/}


                                {loaded && <div className="pt-8 ml-lg-0 ml-md-15">
                                    <div className="d-flex align-items-center justify-content-between  mb-5">
                                        <h5 className="font-size-4 font-weight-normal text-default-color">
                                            <span className="heading-default-color">{alljobs.length} {" "}</span>
                                            {" "} Jobs   {" "}
                                            {(keyword != "") && <span className="heading-default-color">for keyword "{values.keyword}"</span>}
                                        </h5>
                                        <div className="d-flex align-items-center result-view-type">

                                            <a className={`heading-default-color pl-5 font-size-6 hover-text-hitgray ${(showList === true) ? 'active' : ''} pointer`} onClick={() => setShowList(true)}>
                                                <i className="fa fa-list-ul"></i>
                                            </a>

                                            <a className={`heading-default-color pl-5 font-size-6 hover-text-hitgray ${(showList === false) ? 'active' : ''} pointer`} onClick={() => setShowList(false)}>
                                                <i className="fa fa-th-large"></i>
                                            </a>


                                        </div>
                                    </div>
                                    {(showList === false) && <JobSearchgrid jobsList={job} />}
                                    {(showList === true) && <JobSearchlist jobsList={job} />}

                                    {showMore && <div className="text-center pt-5 pt-lg-13">

                                        <a className="text-green font-weight-bold text-uppercase font-size-3 d-flex align-items-center justify-content-center pointer" onClick={() => { LoadMore(); }}>
                                            Load More{" "}
                                            <i className="fas fa-sort-down ml-3 mt-n2 font-size-4"></i>
                                        </a>

                                    </div>}
                                </div>}
                                {!loaded &&
                                    <div className="mb-8 mt-20" data-aos="blink"
                                        data-aos-duration="800"
                                        data-aos-delay="500">
                                        {/* {/*<!-- Single Featured Job -->*/} */}
                                        <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 ">
                                            <div className="row">
                                                <div className="col-md-8  ">
                                                    <div className="media align-items-center w-100">
                                                        <div className="d-block mr-8 mt-n15">
                                                            <div className=" w-100 circle-72  movingLoader">
                                                            </div>

                                                        </div>
                                                        <div className=" w-100">
                                                            <h3 className="mt-0 mb-0 ">

                                                                <div className="font-size-6 min-width-px-96 py-5 mb-1 movingLoader    " >

                                                                </div>

                                                            </h3>

                                                            <span className="font-size-4 movingLoader min-width-px-96 text-default-color line-height-2">

                                                                <span className="font-size-3 min-width-px-96 font-weight-normal movingLoader">    </span>
                                                            </span>
                                                            <ul className="d-flex list-unstyled mr-n8 flex-wrap  justify-content-md-start  w-100 ">

                                                                <>

                                                                    <span className="  pl-3 pr-5 font-size-3">
                                                                    </span></>


                                                                <span className="font-size-3 movingLoader">

                                                                </span>
                                                            </ul>





                                                            <ul className="d-flex list-unstyled mr-n3 flex-wrap  justify-content-md-start  w-100 ">




                                                                <li className="mt-2 mr-5 ">

                                                                    <a className="movingLoader text-green-2 font-size-3 rounded-3">


                                                                    </a>


                                                                </li>



                                                                <li className="mt-2 mr-5 ">
                                                                    <a className="movingLoader text-orange font-size-3 rounded-3 px-2">


                                                                    </a>
                                                                </li>




                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 text-right pt-3  pt-md-3">
                                                    <div className="media justify-content-md-end">

                                                        <div className="image  pl-7 movingLoader">

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <a className="w-100 px-5">  <div className="col-md-12 pb-5 heading-default-color w-100 movingLoader" style={{ textOverflow: 'ellipsis', height: '73px', width: "100%", overflow: 'hidden', wordWrap: "break-word" }} >

                                                </div>

                                                </a>
                                                <div className="col-md-12 pt-5">
                                                    <ul className="d-flex list-unstyled mr-n3 flex-wrap">



                                                        <li  >

                                                            <a className="movingLoader min-width-px-96 mr-3  py-5 text-center  px-6 py-1 font-size-3 text-black-2 mt-2">

                                                            </a>

                                                        </li>
                                                        <li  >

                                                            <a className="movingLoader min-width-px-96 mr-3 py-5 text-center   px-6 py-1 font-size-3 text-black-2 mt-2">

                                                            </a>

                                                        </li>

                                                        <li  >

                                                            <a className="movingLoader min-width-px-96 mr-3 py-5 text-center   px-6 py-1 font-size-3 text-black-2 mt-2">

                                                            </a>

                                                        </li>

                                                        <li  >

                                                            <a className="movingLoader min-width-px-96 py-5 mr-3 text-center  px-6 py-1 font-size-3 text-black-2 mt-2">

                                                            </a>

                                                        </li>


                                                    </ul>
                                                </div>

                                            </div>

                                        </div>
                                        {/* {/*<!-- End Single Featured Job -->*/} */}
                                    </div>}

                                {/* {/*<!-- form end -->*/} */}
                            </div>
                        </div>
                    </div>

                </div>

            </PageWrapper>
        </>
    );
};
