import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import validator from 'validator'; 
import { FileUpload, getFileName } from "../../utils/fileUpload";
import { isInvalid, checkImageURL } from "../../utils/helperFn";
import Instructions from "../../sections/Assessment/Instructions";
import { GetTimeZone, UpdateExpertProfile,   GetExpertProfile } from "../../utils/apiCalls";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
import ClipLoader from "react-spinners/ClipLoader";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import AvatarImageCropper from 'react-avatar-image-cropper';
import ExpertSocial from "./Social";
import ExpertProfessional from "./Professional";
import Education from "./Education";
import Testimonials from "./Testimonials";
import Certificate from "./Certificate";
import Experience from "./Experience";
import { useLocation, useNavigate } from 'react-router-dom';
 
const imgP = "../assets/image/avatar.png";

const Profile = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const data = { profilecomplete: false, idverified: false, browserCountry: '', browserTimezone: '', resetAvatarName: '', avatarName: '', avatar: null, restphotoName: '', photoName: '', photo: null, fName: '', lName: '', city: '', state: '', zip: '', timezoneid: 0, countryid: 0, country: null, timezone: null, address1: '', address2: '', phone: '', languages: '', error: { avatar: '', photo: '', fName: '', lName: '', city: '', state: '', country: '', timezone: '', address1: '', phone: '', address2:"" ,zip:""}, percentage: 10 };
    const gContext = useContext(GlobalContext); 
    const [loaded, setLoaded] = useState(false);
    const [rowsSocial, setRowsSocial] = useState([]);
    const [initialValues, setInitialValues] = React.useState(data);
    const [values, setValues] = React.useState(data);  
    const [timeZone, setTimeZone] = useState([{}]);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(gContext.visibleProfileMessage); 
    const [profileInfo, setProfileData] = React.useState(null);
    const pathName = useLocation().pathname.toLowerCase();
    const [currentPage, setCurrentPage] = useState(1);
    const [profileId, SetProfileId] = React.useState(null);
    const [avatar, setAvatar] = React.useState("");
    const [photoId, setPhotoId] = React.useState("");
    const navigate = useNavigate();

    
    const actions = [

        <button type="button" className="btn btn-sm bg-green m-r-5" key={0}><i className="m-icon material-icons text-white  " >close</i></button>,
        <button type="button" className="btn btn-sm bg-green" key={1}><span className="m-icon material-icons text-white " >check</span></button>,
    ]

    
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);

    useEffect(() => {
         
        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);
    

    function gotoPage(code) {
        if (gContext.userInfo.userID) {
            gContext.setCurrentPage(code); gContext.setVisibleProfileMessage(false);
        } else { return; }
    }
    
    /*  Check validity of the fields on submit */
    function checkValidity() {
        let inValid = false;
        let { error, ...rest } = values;       

        error.fName = validator.isEmpty(rest.fName) ? "First Name is required" : (isInvalid(rest.fName) ? "Invalid format" : "");
        error.lName = validator.isEmpty(rest.lName) ? "Last Name is required" : (isInvalid(rest.lName) ? "Invalid format" : "");
        error.country = (rest.country) ? "" : "Country is required";
        error.timezone = (rest.timezone) ? "" : "TimeZone is required";
        if (rest.avatarName == '')
            error.avatar = (rest.avatar) ? "" : "Profile avatar is required";
        if (rest.photoName == '')
            error.photo = (rest.photo) ? "" : "ID is required";
        error.address1 = validator.isEmpty(rest.address1) ? "Address is required" : (isInvalid(rest.address1) ? "Invalid format" : "");
        error.address2 =  (isInvalid(rest.address2) ? "Invalid format" : "");
        error.city = validator.isEmpty(rest.city) ? "City is required" : (isInvalid(rest.city) ? "Invalid format" : "");
        error.state = validator.isEmpty(rest.state) ? "State is required" : (isInvalid(rest.state) ? "Invalid format" : "");
        error.phone = rest.phone ? (validator.isEmpty(rest.phone) ? "Phone Number is required" : ((isInvalid(rest.phone)) ? "Invalid format" : error.phone)) :"Phone Number is required";
        error.zip = rest.zip ? ((isInvalid(rest.zip) || validator.isURL(rest.zip ? rest.zip : "") || rest.zip < 0 || rest.zip.length > 10) ? "Invalid format" : "") : "Zip is required";
        setValues({ ...values, error });

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        }); 

        if (inValid)
            return false;
        else
            return true;

    }

    /*  Update Profile */
    async function updateProfile() {
        gContext.setVisibleProfileMessage(false);
        setErrorMessage('');
        setMessage(false);


        if (checkValidity()) {

            gContext.setLoaderModalVisible(true);
            var data = { ...values };
            if (values.avatar) {

                data.avatarName = getFileName(values.avatar, "avatar");
                FileUpload(values.avatar, data.avatarName.split('/').pop());
            }
            if (values.photo) {

                data.photoName = getFileName(values.photo, "photoid");
                FileUpload(values.photo, data.photoName.split('/').pop());
            }
            try {

                const result = await UpdateExpertProfile(data);

                if (result.data.expertPerUpdate && result.data.expertPerUpdate.responseData.code == "200") {
                     setMessage(true); 

                    gContext.getProfileInfo(gContext.userInfo.userType, gContext.userInfo.email, gContext.userInfo.sub);
                    setCurrentPage(2); gContext.setCurrentPage("6"); gContext.setVisibleProfileMessage(false); navigate("/profile", { replace: true });
                }
                else {
                    setErrorMessage(JSON.parse(result.data.expertPerUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                setErrorMessage("Server error");
            }
        }
        else {
            setMessage(false);
            setErrorMessage("Required fields are missing / Invalid input format.");

        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });


    }

    

    useEffect(() => {

        if (values.photoName) {
            checkImageURL(s3URL + values.photoName)
                .then(isValid => {
                    if (isValid) {
                        setPhotoId(s3URL + values.photoName);
                    } else {
                        setPhotoId("../images/pdf.png");
                    }
                });
        }

    }, [values.photoName])
   
    
    /* Fields change event */
    const handleFieldsChange = (e) => {
        setErrorMessage('');
        gContext.setVisibleProfileMessage(false);
        setMessage(false);
        const { name, value } = e.target;
        let error = { ...values.error };
        switch (name) {

            case "fName":
                error.fName = validator.isEmpty(value) ? "First Name is required" : "";

                break;
            case "lName":
                error.lName = validator.isEmpty(value) ? "Last Name is required" : "";
                break;
            case "phone":
                const regex = /^\+?\(?([0-9]{3})\)?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                error.phone = ((value.match(regex)) || value.trim() == "") ? "" : "Phone number is not valid";
                break;
            
            default:
                if (value != '' || value != null)
                    error[name] = "";

                break;
        }
        if (name != "avatar" && name != "photo") {
            if (name!="zip")
                setValues({ ...values, error, [name]: value.trim() });
            else
                setValues({ ...values, error, [name]: value == "" ? "" : (value.match(/[0-9]$/) ? value.trim() : values[name]) });
        }
        else {

            if (e.target.files.length > 0)
                setValues({ ...values, error, [name]: e.target.files[0], [name + "Name"]: '' });
        }
    }
    /* Select change event */
    const handleSelectChange = (e, name) => {
        setErrorMessage('');
        gContext.setVisibleProfileMessage(false);
        setMessage(false);
        const value = e;

        let error = { ...values.error };
        switch (name) {
            case "country":

                if (value != "") {

                    bindTimezone(e);
                }
                error.country = "";

                setValues({ ...values, error, [name]: value, countryid: value.value, ["timezone"]: null, ["timezoneid"]: 0 });

                break;
            case "timezone":
                if (value) {
                    error.timezone = "";
                    setValues({ ...values, error, [name]: value, [name + 'id']: value.value });
                }
                break;
            default:

                break;
        }



    }
    async function bindTimezone(value) {
         
        try {
            if (value) {
                let timezoneList = [];
                setLoader(true);
                const result = await GetTimeZone(value.code);

                if (result.data && result.data.timezone.responseData) {

                    if (result.data.timezone.responseData.code == "200") {
                       
                        JSON.parse(result.data.timezone.responseData.message).map((value) => (
                            timezoneList.push({ value: value.id, label: '(' + value.tzvalue + ') ' + value.tzname })));

                    }

                }
                setTimeZone(timezoneList);
            }
            setLoader(false);
        } catch (e) {
            console.log(e);
            setLoader(false);
        }
    }
    
    /*Cancel profile edit*/
    function cancelEdit() {
 
        setErrorMessage("");
        setMessage("");
        if (gContext.userInfo.profileInfo != null) {
           
            BindProfileInfo();
                          
        }         
        if (gContext.userInfo.profileInfo == null) {
           
            setValues(initialValues);
            BindProfileInfo();
        }
    }
    /*For Social table*/
    function addTableRows() {
        const rowsInput = {
            id: null, expertId: gContext.userInfo.userID, provider: null,
            link: '', error: { provider: '', link: '' }
        }
        setRowsSocial([...rowsSocial, rowsInput]);

    }
    
    
   
     

    useEffect(() => {

        if (gContext.userInfo.userID == null) {
            setLoaded(true);
            gContext.setLoaderModalVisible(false);
        }
       
        setProfileData(gContext.userInfo.profileInfo);

        if (gContext.countriesList.length >= 1 && gContext.userInfo.userID)
           BindProfileInfo();

    }, [gContext.countriesList, gContext.userInfo])

    /* To bind on load */
    useEffect(() => {

        document.title = "Profile | Syncuppro";
        if (gContext.countriesList.length <= 1)
            gContext.LoadCountry();

        let url = window.location.href.split('#');
        if (gContext.userInfo.userID != null && url.length > 0 && url[1] && url[1].toLowerCase() == "certificate") {
            setCurrentPage(3);
        }
        else if (gContext.userInfo.userID != null && gContext.userInfo.idVerified && gContext.userInfo.profileComplete && pathName.trim().toLowerCase()   == "/assessment") {

            setCurrentPage(4);
        }
        
    }, [])
    
    useEffect(() => {
        if (gContext.userInfo.profileInfo != null && (values.timezoneid != '' && values.timezone == null)) { //
            
            const country = gContext.countriesList.filter(item => item["value"] == values.countryid);
            const timezone = timeZone.filter(item => item["value"] == values.timezoneid);
            setValues({ ...values, ["timezone"]: (timezone.length > 0) ? timezone[0] : null, ["country"]: (country.length > 0) ? country[0] : null });
        }

    }, [timeZone]);
    
    async function BindProfileInfo() {
        setLoaded(false);
        try {
            const result = await GetExpertProfile();

            if (result.data.expertPerLoad && result.data.expertPerLoad.responseData.code == "200") {

                
                let profile = JSON.parse(result.data.expertPerLoad.responseData.message);

                let profileData = data;
                const country = gContext.countriesList.filter(item => item["value"] == profile[0].countryid);

                const timezone = timeZone.filter(item => item["value"] == profile[0].timezoneid);
                profileData.resetAvatarName = (profile[0].avatar && profile[0].avatar != "null") ? profile[0].avatar : "";
                profileData.resetPhotoName =( profile[0].photo && profile[0].photo != "null" )? profile[0].photo : "";

                profileData.avatarName =( profile[0].avatar && profile[0].avatar != "null") ? profile[0].avatar : "";
                profileData.photoName =( profile[0].photo && profile[0].photo != "null") ? profile[0].photo: "";
                profileData.fName = (profile[0].firstname && profile[0].firstname != "null") ? profile[0].firstname : ""; 
                profileData.lName = (profile[0].lastname && profile[0].lastname != "null") ? profile[0].lastname : ""; 
                profileData.city = (profile[0].city && profile[0].city != "null") ? profile[0].city : ""; 
                profileData.state = (profile[0].state && profile[0].state != "null") ? profile[0].state : "";  
                profileData.zip = (profile[0].zip && profile[0].zip != "null") ? profile[0].zip : "";
                profileData.countryid = (profile[0].countryid) ? profile[0].countryid : 0;
                profileData.timezoneid = (profile[0].timezoneid) ? profile[0].timezoneid : 0;
                profileData.address1 = (profile[0].address1 && profile[0].address1 != "null") ? profile[0].address1 : '';  
                profileData.address2 = (profile[0].address2 && profile[0].address2 != "null") ? profile[0].address2 : ''; 
                profileData.phone = (profile[0].phone && profile[0].phone != "null") ? profile[0].phone:"";
                profileData.percentage = profile[0].profilePercentage;
                profileData.profilecomplete = profile[0].profilecomplete;
                profileData.idverified = profile[0].idverified;
                profileData.country = (country.length > 0) ? country[0] : null;
                profileData.timezone = (timezone.length > 0) ? timezone[0] : null;
                profileData.languages = (profile[0].otherlangs && profile[0].otherlangs != "null") ? profile[0].otherlangs : '';
                SetProfileId(profile[0].id);
                setInitialValues(profileData);
                setValues(profileData);

                setTimeout(() => {
                    bindTimezone((country.length > 0) ? country[0] : null);
                    gContext.setLoaderModalVisible(false);
                    setLoaded(true);
                  
                }, 1000);

            }
            else {
                gContext.setLoaderModalVisible(false);
                setLoaded(true);
               setValues(data);
            }

        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setLoaded(true);
        }
    }

    useEffect(() => {
        if (loaded)
            gContext.setPageLoading(false);
    }, [loaded]);

    const apply = (file) => {
        // handle the blob file you want
        // such as get the avatar src
        let error = { ...values.error };
        error.avatar = "";
        var src = window.URL.createObjectURL(file);
        setValues({ ...values, error, ["avatar"]: file });
    }
    const errorHandler = (type) => {
        let error = { ...values.error };
        error.avatar = (type === "not_image") ? "Upload image file." : type;
        setValues({ ...values, error });
    }

    const popoverAvatar= (
        <Popover className="   " >            

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p   >
                    Allowed format jpeg,png,jpg. Max file size 5MB
                 </p>

            </div>


            
        </Popover>
    );
    const popoverPhotoID = (
        <Popover className="   " >
 
            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>
                    Allowed format pdf,jpg,png,jpeg file. <br /> ID verification requires  First name, Last name to match the Photo ID  <br /> Example: Driver�s Licence, Passport (photo & address page), etc.,<br /> When photo id is approved, no new uploads will be supported
                                                                   </p>

            </div>
           

        </Popover>
    );
    
    return (
        <>

            <div className="page-wrapper">

                <div className="breadcrumb">
                    <div className="container-fluid">

                        <div className="breadcrumb-wrapper">
                            <h4>MY PROFILE</h4> 
                            
                            <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / {(currentPage == 1) ? "Personal" : (currentPage == 2 ? "Professional" : (currentPage == 3 ? "Certificate" : (currentPage == 5 ? "Education" : (currentPage == 6 ? "Experience" : (currentPage == 7 ? "Socials" : currentPage == 4 ? "Assessment" : "Testimonials")))))}</span>
                            </div>

                        </div>

                    </div>
                </div>

                {loaded && <section className="section-sm">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="profile-status-wrapper">

                                <div className="profile-status">
                                    <span>Profile Status :</span>

                                    {(values.profilecomplete && profileInfo && profileInfo.length > 0 && profileInfo[0].assessment) ? <>  <img className="verified-img m-l-15" src="images/verified_img.svg" alt="" />
                                        <b className="m-l-10">Verified</b></> : <>  <img className="verified-img m-l-15" src="images/notverified_img.svg" alt="" />
                                        <b className="m-l-10">Not Verified</b></>}


                                </div>

                                <div className="profile-progressbar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: ((values.percentage) ? values.percentage : 10).toString() + "%" }}></div>
                                    </div>
                                    <b className="m-l-10">{((values.percentage) ? values.percentage : 10).toString() + '%'}</b>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>}

                {!loaded && <section className="section-sm">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="profile-status-wrapper">

                                <div className="profile-status">
                                    <span>Profile Status :</span>
                                    <div className="m-l-10 w-200 movingLoader">&nbsp;</div>
                                </div>

                                <div className="profile-progressbar">
                                    <div className="progress">
                                        <div className="w-200 movingLoader">&nbsp;</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>}
                {loaded && !profileInfo && <>
                    <section className="section-sm pt-0">
                        <div className="container-fluid">
                            <div className="verification-status-wrap">
                                <span className="material-icons text-gray f-36 m-r-20">info</span>
                                <div className="row verification-status-block">
                                    {!profileInfo && <>
                                        <div className="col-12">
                                            <div className="verification-list pending text-red">
                                                <span className="material-icons d-none">close</span>
                                                {ConfirmMessages.ExpertInitialMessage}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="verification-list pending text-red">
                                                <span className="material-icons d-none">close</span>
                                                {ConfirmMessages.ProfilenotCompleted}
                                            </div>
                                        </div>

                                    </>}</div></div></div></section>
                </> }
                {loaded && gContext.userInfo.userID != null && <>

                    {profileInfo && profileInfo.length > 0 && <>
                        {(!values.profilecomplete && values.idverified) &&

                            <section className="section-sm pt-0">
                                <div className="container-fluid">
                                    <div className="verification-status-wrap">
                                        <span className="material-icons text-gray f-36 m-r-20">info</span>    <div className="row verification-status-block">


                                            <div class="col-12">
                                                <div class="verification-list completed text-green">
                                                    <span class="material-icons d-none">done</span>
                                                    {ConfirmMessages.ProfileidVerified}
                                                </div>
                                            </div>
                                            {(profileInfo && profileInfo.length > 0 && profileInfo[0].certrejectreason == null) ?
                                                <div className="col-12">
                                                    <div className="verification-list pending text-red">
                                                        <span className="material-icons d-none">close</span>
                                                        {ConfirmMessages.CertificatePending}
                                                    </div>
                                                </div>

                                                :

                                                <>


                                                    {(profileInfo && profileInfo.length > 0 && profileInfo[0].certrejectreason && profileInfo[0].certrejectreason != "" && values.profilecomplete == false) &&
                                                        <div className="col-12">
                                                            <div className="verification-list pending text-red">
                                                                <span className="material-icons d-none">close</span>
                                                                {ConfirmMessages.CertificatenotApproved} :

                                                                <span className=" font-italic ">  {" Reason -" + profileInfo[0].certrejectreason} </span>
                                                            </div>
                                                        </div>
                                                    }</>}

                                            {(profileInfo && profileInfo.length > 0 && !profileInfo[0].assessment) && <div className="col-12">
                                                <div className="verification-list pending text-red">
                                                    <span className="material-icons d-none">close</span>
                                                    {ConfirmMessages.ProfilenotCompleted}
                                                </div>
                                            </div>
                                            }

                                        </div></div>
                                </div>
                            </section>
                        }



                        {(values.profilecomplete && !values.idverified) &&

                            <section className="section-sm pt-0">
                                <div className="container-fluid">
                                    <div className="verification-status-wrap">
                                        <span className="material-icons text-gray f-36 m-r-20">info</span>

                                        <div className="row verification-status-block">
                                            <div class="col-12">
                                                <div class="verification-list completed text-green">
                                                    <span class="material-icons d-none">done</span>
                                                    {ConfirmMessages.CertificateApproved}
                                                </div>
                                            </div>

                                            {(profileInfo && profileInfo.length > 0 && !values.idverified && profileInfo[0].idrejectreason == null) ?

                                                <div className="col-12">
                                                    <div className="verification-list pending text-red">
                                                        <span className="material-icons d-none">close</span>
                                                        {ConfirmMessages.Profileidpending}
                                                    </div>
                                                </div> :
                                                <>  {(profileInfo && profileInfo.length > 0) && <>
                                                    <div className="col-12">
                                                        <div className="verification-list pending text-red">
                                                            <span className="material-icons d-none">close</span>
                                                            {ConfirmMessages.ProfileidnotApproved}  {(profileInfo && profileInfo.length > 0 && profileInfo[0].idrejectreason && profileInfo[0].idrejectreason != "") &&
                                                                <span className=" font-italic  ">{"- Reason :" + profileInfo[0].idrejectreason}</span>}
                                                        </div>
                                                    </div></>

                                                }</>}


                                            {(profileInfo && profileInfo.length > 0 && !profileInfo[0].assessment) && <div className="col-12">
                                                <div className="verification-list pending text-red">
                                                    <span className="material-icons d-none">close</span>
                                                    {ConfirmMessages.ProfilenotCompleted}
                                                </div>
                                            </div>
                                            }
                                        </div></div>
                                </div>
                            </section>


                        }
                       
                        {(!values.profilecomplete && !values.idverified) &&
                            <section className="section-sm pt-0">
                                <div className="container-fluid">
                                    <div className="verification-status-wrap">
                                        <span className="material-icons text-gray f-36 m-r-20">info</span>
                                        <div className="row verification-status-block">
                                            

                                            {profileInfo && profileInfo.length > 0 &&

                                                (profileInfo[0].idrejectreason != null && profileInfo[0].idrejectreason != '' && !values.idverified) ?
                                                <> <div className="col-12">
                                                    <div className="verification-list pending text-red">
                                                        <span className="material-icons d-none">close</span>
                                                        {ConfirmMessages.ProfileidnotApproved}  <span className=" font-italic ">{"- Reason :" + profileInfo[0].idrejectreason}</span>
                                                    </div>
                                                </div>
                                                </> :
                                                <>
                                                    {(profileInfo && profileInfo.length > 0 && (profileInfo[0].photo && profileInfo[0].title ==null && (profileInfo[0].exptcerts.length ==0))) &&
                                                  <>  <div className="col-12">
                                                        <div className="verification-list pending text-red">
                                                            <span className="material-icons d-none">close</span>
                                                            {ConfirmMessages.Profileidpending}
                                                        </div>
                                                    </div>
                                                        <div className="col-12">
                                                            <div className="verification-list pending text-red">
                                                                <span className="material-icons d-none">close</span>
                                                                {ConfirmMessages.ExpertProfessionalMessage}
                                                            </div>
                                                        </div></>
                                                    }
                                                    {(profileInfo && profileInfo.length > 0 && profileInfo[0].title != null && profileInfo[0].photo == null && profileInfo[0].certificates.length == 0 ) &&
                                                        <div className="col-12">
                                                            <div className="verification-list pending text-red">
                                                                <span className="material-icons d-none">close</span>
                                                                {ConfirmMessages.ExpertPersonalMessage}
                                                            </div>
                                                        </div>

                                                    }
                                                    {(profileInfo && profileInfo.length > 0 && profileInfo[0].title != null && profileInfo[0].photo != null && profileInfo[0].certificates.length == 0) &&
                                                       <> <div className="col-12">
                                                        <div className="verification-list pending text-red">
                                                            <span className="material-icons d-none">close</span>
                                                            {ConfirmMessages.Profileidpending}
                                                        </div>
                                                    </div> <div className="col-12">

                                                            <div className="verification-list pending text-red">
                                                                <span className="material-icons d-none">close</span>
                                                                {ConfirmMessages.ExpertCertificateMessage}
                                                            </div>
                                                        </div></>

                                                    }
                                                    {(profileInfo && profileInfo.length > 0 && profileInfo[0].title != null && profileInfo[0].photo != null && profileInfo[0].certificates.length > 0) &&
                                                        <> <div className="col-12">
                                                            <div className="verification-list pending text-red">
                                                                <span className="material-icons d-none">close</span>
                                                                {ConfirmMessages.Profileidpending}
                                                            </div>
                                                        </div> <div className="col-12">

                                                                <div className="verification-list pending text-red">
                                                                    <span className="material-icons d-none">close</span>
                                                                {ConfirmMessages.CertificatePending}
                                                                </div>
                                                            </div></>

                                                    }
                                                </>

                                            }
                                            {profileInfo && profileInfo.length > 0 &&

                                                (profileInfo[0].certrejectreason != null && profileInfo[0].certrejectreason != '' && !values.profilecomplete) ?
                                                <> {(profileInfo && profileInfo.length > 0) && <>

                                                    <div className="col-12">
                                                        <div className="verification-list pending text-red">
                                                            <span className="material-icons d-none">close</span>
                                                            {ConfirmMessages.CertificatenotApproved} <span className=" font-italic ">{"- Reason :" + profileInfo[0].certrejectreason}</span>
                                                        </div>
                                                    </div>
                                                </>}</>
                                                : <> {profileInfo[0].exptcerts.length == 0 && < div className="col-12 d-none">
                                                    <div className="verification-list pending text-red">
                                                        <span className="material-icons d-none">close</span>
                                                        {ConfirmMessages.CertificatePending}
                                                    </div>
                                                </div>}</>
                                            }


                                            <>{(profileInfo && profileInfo.length > 0 && !profileInfo[0].assessment) &&
                                                <div className="col-12">
                                                    <div className="verification-list pending text-red">
                                                        <span className="material-icons d-none">close</span>
                                                        {ConfirmMessages.ProfilenotCompleted}
                                                    </div>
                                                </div>
                                            }</>

                                        </div> </div>
                                </div>
                            </section>

                        }

                        {(values.profilecomplete && values.idverified && (profileInfo && profileInfo.length > 0 && !profileInfo[0].assessment)) &&
                            <section className="section-sm pt-0">
                                <div className="container-fluid">
                                    <div className="verification-status-wrap">
                                        <span className="material-icons text-gray f-36 m-r-20">info</span>  <div className="row verification-status-block">
                                            <>{(profileInfo && profileInfo.length > 0 && !profileInfo[0].assessment) &&
                                                <div className="col-12">
                                                    <div className="verification-list pending text-red">
                                                        <span className="material-icons d-none">close</span>
                                                        {ConfirmMessages.ProfilenotCompleted}
                                                    </div>
                                                </div>

                                            }</>
                                        </div></div>
                                </div>
                            </section>
                        }
                    </>}



                </>}


                <section className="section experts-profile p-t-0">
                    <div className="container-fluid">

                        <div className="tab-wrapper-block sticky-top">

                            <div className="previous">
                                {(currentPage != 1) && <a href="#" onClick={() => { if (currentPage == 5 && !(gContext.userInfo.idVerified && gContext.userInfo.profileComplete)) { setCurrentPage(3); } else if (currentPage != 1) setCurrentPage(currentPage - 1) }}>
                                    <span className="m-icon material-icons">arrow_back_ios</span>Previous
                                </a>}
                                {(currentPage == 1) && <a onClick={() => { if (currentPage == 5 && !(gContext.userInfo.idVerified && gContext.userInfo.profileComplete)) { setCurrentPage(3); } else if (currentPage != 1) setCurrentPage(currentPage - 1) }} className="text-gray">
                                    <span className="m-icon material-icons">arrow_back_ios</span>Previous
                                </a>}
                            </div>

                            <div className="tab-wrapper m-b-0">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className={`btn tab-btn   m-r-10 ${(currentPage === 1) ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => {
                                            setCurrentPage(1); gContext.setCurrentPage("5"); gContext.setVisibleProfileMessage(false); navigate("/profile", { replace: true });
                                        }} aria-selected="true" role="tab">Personal</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={`btn tab-btn   m-r-10 ${(currentPage === 2) ? 'active' : ''}`} onClick={() => {
                                            setCurrentPage(2); gContext.setCurrentPage("6"); gContext.setVisibleProfileMessage(false); navigate("/profile", { replace: true });
                                        }}>Professional</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ cursor: (gContext.userInfo.userID || profileId) ? '' : 'not-allowed' }} className={`btn tab-btn   m-r-10 ${(currentPage === 3) ? 'active' : ''}`} onClick={() => {
                                            if (gContext.userInfo.userID || profileId   ) { setCurrentPage(3); gotoPage('12'); navigate("/profile", { replace: true }); }
                                        }}>
                                            Certificate  </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ cursor: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) ? '' : 'not-allowed' }} className={`btn tab-btn   m-r-10 ${(currentPage === 4) ? 'active' : ''}`} onClick={() => {
                                            if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) { setCurrentPage(4); gotoPage('12'); navigate("/profile", { replace: true }); }
                                        }}>
                                            Assessment  </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ cursor: ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) || profileId) ? '' : 'not-allowed' }} className={`btn tab-btn   m-r-10 ${(currentPage === 5) ? 'active' : ''}`} onClick={() => {
                                            if ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) || profileId) { setCurrentPage(5); gotoPage('11'); navigate("/profile", { replace: true }); }
                                        }}>
                                            Education</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ cursor: ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) || profileId) ? '' : 'not-allowed' }} className={`btn tab-btn   m-r-10 ${(currentPage === 6) ? 'active' : ''}`} onClick={() => {
                                            if ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title)|| profileId) { setCurrentPage(6); gotoPage('13'); navigate("/profile", { replace: true }); }
                                        }}>
                                            Experience</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ cursor: ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) || profileId) ? '' : 'not-allowed' }} className={`btn tab-btn   m-r-10 ${(currentPage === 7) ? 'active' : ''}`} onClick={() => {
                                            if ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) || profileId) { setCurrentPage(7); gotoPage('16'); navigate("/profile", { replace: true }); }
                                        }}>
                                            Socials</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a style={{ cursor: ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) || profileId) ? '' : 'not-allowed' }} className={`btn tab-btn   m-r-10 ${(currentPage === 8) ? 'active' : ''}`} onClick={() => {
                                            if ((gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title)|| profileId) { setCurrentPage(8); gotoPage('14'); navigate("/profile", { replace: true }); }
                                        }}>Testimonials</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="next ">
                                {(currentPage != 8) && <a href="#"   onClick={() => {
                                    if (profileId || (gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title)) {
                                        if (currentPage == 3 && !(gContext.userInfo.idVerified && gContext.userInfo.profileComplete)) { setCurrentPage(5) } else if (currentPage != 8) setCurrentPage(currentPage + 1)
                                    }
                                    else { if (currentPage == 1) setCurrentPage(currentPage + 1) }
                                }}>
                                    Next <span className="m-icon material-icons">arrow_forward_ios</span>
                                </a>}
                                {(currentPage == 8) && <a onClick={() => {
                                    
                                    if (gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) {
                                        if (currentPage == 3 && !(gContext.userInfo.idVerified && gContext.userInfo.profileComplete)) { setCurrentPage(5) } else if (currentPage != 8) setCurrentPage(currentPage + 1)
                                    }
                                    else { if (currentPage == 1) setCurrentPage(currentPage + 1) }
                                }} className="text-gray">
                                    Next <span className="m-icon material-icons">arrow_forward_ios</span>
                                </a>}
                            </div>

                        </div>

                        <div className="row d-none ">
                            {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                            {errorMessage !== "" && <><span className="text-red">{errorMessage} </span><br /></>}
                        </div>

                        <div className="tab-content">
                            {(gContext.userInfo.userType === "1" && (currentPage == 1)) ? <>
                                {loaded &&
                                    <div id="personal" className="tab-pane active show" role="tabpanel">




                                        <div className="card panel  p-0">

                                            <div className="card-header">

                                                <div className="client-title">
                                                    <h6>PERSONAL</h6>
                                                </div>

                                                <div className="panel-action">
                                                    <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                        updateProfile();

                                                    }}>Save</button>
                                                    <button type="button" onClick={() => { cancelEdit(); }} className="btn btn-dark-outline btn-sm text-black d-flex align-item-center">Cancel</button>

                                                </div>

                                            </div>

                                            <div className="card-body p-b-0">
                                                <div className="mandatory-field m-b-30">
                                                    <span className="text-red">All * fields are mandatory</span>
                                                </div>


                                                <div className="row">

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="first name" className="form-label m-b-10 d-flex">Avatar/Profile Photo Upload<span className="text-red m-l-5"> * </span> <OverlayTrigger trigger="hover" placement="auto" overlay={popoverAvatar} >
                                                                <span className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                                            </OverlayTrigger>
                                                            </label>
                                                            {values.error.avatar.length > 1 && (
                                                                <>   <span className="text-red ">{values.error.avatar}</span></>
                                                            )}


                                                            {(!values.avatarName ) && <>
                                                                {(!values.avatar) &&

                                                                    <div style={{ height: '210px', width: '170px' }} className="m-b-20" >
                                                                        <div style={{ height: '180px', width: '180px' }} className="  w-full">
                                                                            <AvatarImageCropper maxsize={5000000} apply={apply} icon={<span className="m-icon material-icons"
                                                                                htmlFor="idUpload">  </span>} actions={actions} errorHandler={errorHandler}
                                                                                text={<span className="form-control upload"  >Click here to upload</span>} />
                                                                        </div></div>}
                                                                {(values.avatar) &&


                                                                    <div className="upload-doc-img">
                                                                        <img src={(values.avatar) ? URL.createObjectURL(values.avatar) : imgP} alt=" " />
                                                                        <div className="btn-edit pointer" data-toggle="tooltip" title="Remove avatar"
                                                                            onClick={() => setValues({ ...values, ['avatar']: null, ['avatarName']: values.resetAvatarName })}>
                                                                            <span className="m-icon material-icons">delete</span>

                                                                        </div>

                                                                    </div>
                                                                }</>}
                                                            {(values.avatarName) &&
                                                                <div className="upload-doc-img">
                                                                    <img id="imgavatar" src={s3URL + values.avatarName} onError={(img) => { img.target.src = '../images/user-default.png'; }} alt="" />
                                                                    <div className="btn-edit pointer" data-toggle="tooltip" title="Remove avatar"
                                                                        onClick={() => setValues({ ...values, ['avatar']: null, ['avatarName']: "" })}>
                                                                        <span className="m-icon material-icons">delete</span>

                                                                    </div>

                                                                </div>
                                                            }
                                                            <input
                                                                type="file"
                                                                id="fileUpload"
                                                                name="avatar" key={values.avatar}
                                                                className="sr-only d-none" accept="image/png,image/jpeg,image/jpg"
                                                                onChange={handleFieldsChange} onSelect={handleFieldsChange}
                                                            />




                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="first name" className="form-label m-b-10 d-flex">Government Issued Photo ID Upload<span className="text-red m-l-5"> * </span><OverlayTrigger trigger="hover" placement="auto" overlay={popoverPhotoID} ><span className="text-theme m-icon material-icons m-l-10 pointer" >info</span></OverlayTrigger></label>
                                                            {values.error.photo.length > 1 && (
                                                                <> <br /> <span className="text-red">{values.error.photo}</span></>
                                                            )} 

                                                            {(values.photoName === "" || values.photoName == null) && <div>{(!values.photo) && <>

                                                                <label className="upload-file p-relative pointer" htmlFor="idUpload">
                                                                    <span className="text-light-gray m-icon material-icons m-r-10 pointer" htmlFor="idUpload">upload_file</span>Click here to add photo ID

                                                                </label>
                                                            </>}
                                                                {(values.photo) && <> {(values.photo["name"].split('.').pop().toLowerCase() !== 'pdf') ? <>

                                                                    <div className="upload-doc-img m-b-10">
                                                                        <div className="workers-img">
                                                                            <img src={URL.createObjectURL(values.photo)} htmlFor="idUpload" />
                                                                            <div className="btn-icon-close" data-toggle="tooltip" title="Remove Photo ID" onClick={() => setValues({ ...values, ['photo']: null, ['photoName']: values.resetPhotoName })}  >
                                                                                <span className="m-icon material-icons">close</span>
                                                                            </div>
                                                                        </div>




                                                                    </div>



                                                                </> :
                                                                    <>
                                                                        <div className="upload-doc-img m-b-10">
                                                                            <div className="workers-img">
                                                                                <img src="../images/pdf.png" htmlFor="idUpload" />


                                                                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Photo ID" onClick={() => setValues({ ...values, ['photo']: null })}>
                                                                                    <span className="m-icon material-icons">close</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                        <span className="text-gray" >{values.photo["name"]}</span>



                                                                    </>



                                                                }

                                                                </>}
                                                            </div>}

                                                            {(values.photoName && values.photoName !== "" && (values.idverified == null || values.idverified === false)) && <>
                                                                {(values.photoName.split('.').pop().toLowerCase() !== 'pdf') ? <>

                                                                    <div className="upload-doc-img m-b-10">
                                                                        <div className="workers-img">
                                                                            <img id="photo" src={s3URL + values.photoName} onError={(img) => { img.target.src = '../images/user-default.png'; }} htmlFor="idUpload" />
                                                                            <div className="btn-icon-close" data-toggle="tooltip" title="Remove Photo ID" onClick={() => setValues({ ...values, ['photo']: null, ['photoName']: "" })}>
                                                                                <span className="m-icon material-icons">close</span>
                                                                            </div>
                                                                        </div>


                                                                      

                                                                    </div>
                                                                </> : <>
                                                                    <div className="upload-doc-img m-b-10">
                                                                        <div className="workers-img ">
                                                                            <img src="../images/pdf.png" htmlFor="idUpload" data-toggle="tooltip" title="Click here to view." onClick={(e) => { window.open(s3URL + values.photoName, "_blank") }} />
                                                                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Photo ID" onClick={() => setValues({ ...values, ['photo']: null, ['photoName']: "" })}>
                                                                                    <span className="m-icon material-icons">close</span>
                                                                                </div>
                                                                            </div>

                                                                      

                                                                    </div>

                                                                </>} </>}
                                                            {(values.photoName && values.photoName !== "" && values.idverified === true) && <> {(values.photoName.split('.').pop().toLowerCase() !== 'pdf') ? <>
                                                                <div className="upload-doc-img m-b-10 ">
                                                                    <div className="workers-img ">
                                                                        <img src="../images/user-default.png" htmlFor="idUpload"   />

                                                                    </div>


                                                                </div></> :
                                                                <>  <div className="upload-doc-img m-b-10">
                                                                    <div className="workers-img ">
                                                                        <img src="../images/pdf.png" htmlFor="idUpload"   />
                                                                    </div>


                                                                </div> </>} </>}

                                                            <input
                                                                type="file"
                                                                id="idUpload" key={values.photo}
                                                                name="photo"
                                                                className="sr-only d-none" accept="image/png,image/jpeg,image/jpg,application/pdf "
                                                                onChange={handleFieldsChange} onSelect={handleFieldsChange}
                                                            />








                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="first name" className="form-label m-b-10">First Name <span className="text-red"> * </span></label>
                                                            <input name="fName"
                                                                defaultValue={values.fName} Value={values.fName}
                                                                disabled={values.idverified}
                                                                type="text"
                                                                className={`form-control   ${(values.error.fName.length > 0) ? 'error' : ''}`}
                                                                id="fName"
                                                                placeholder="First Name" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />{values.error.fName.length > 1 && (
                                                                <span className="text-red">{values.error.fName}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="last name" className="form-label m-b-10">Last Name <span className="text-red"> * </span></label>
                                                            <input name="lName" disabled={values.idverified}
                                                                type="text" defaultValue={values.lName}
                                                                className={`form-control   ${(values.error.lName.length > 0) ? 'error' : ''}`}
                                                                id="lName"
                                                                placeholder="Last Name" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />  {values.error.lName.length > 1 && (
                                                                <span className="text-red">{values.error.lName}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="address" className="form-label m-b-10">Address 1<span className="text-red"> * </span></label>
                                                            <input name="address1"
                                                                type="text" defaultValue={values.address1}
                                                                className={`form-control   ${(values.error.address1.length > 0) ? 'error' : ''}`}
                                                                id="address1"
                                                                placeholder="Address" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />{values.error.address1.length > 1 && (
                                                                <span className="text-red">{values.error.address1}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="address" className="form-label m-b-10">Address 2<span className="text-red"></span></label>
                                                            <input name="address2"
                                                                type="text" defaultValue={values.address2} onKeyUp={handleFieldsChange}
                                                                className="form-control h-px-48"
                                                                id="address2"
                                                                placeholder="Address" onChange={handleFieldsChange}
                                                            />
                                                            {values.error.address2.length > 1 && (
                                                                <span className="text-red">{values.error.address2}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="city" className="form-label m-b-10">City<span className="text-red"> * </span></label>
                                                            <input name="city" defaultValue={values.city ? values.city.toLowerCase() : ''}
                                                                type="text"
                                                                className={`form-control  ${(values.city != null && values.city.length > 2) ? 'text-capi' : 'text-upper'} ${(values.error.city.length > 0) ? 'error' : ''}`}
                                                                id="city"
                                                                placeholder="City" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />{values.error.city.length > 1 && (
                                                                <span className="text-red">{values.error.city}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="state" className="form-label m-b-10">State<span className="text-red"> * </span></label>
                                                            <input
                                                                name="state"
                                                                type="text" defaultValue={values.state ? values.state.toLowerCase() : ''}
                                                                className={`form-control  ${(values.error.state.length > 0) ? 'error' : ''}    ${(values.state != null && values.state.length > 2) ? 'text-capi' : 'text-upper'}`}
                                                                id="state"
                                                                placeholder="State" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />{values.error.state.length > 1 && (
                                                                <span className="text-red">{values.error.state}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="zip" className="form-label m-b-10">
                                                                Zip<span className="text-red"> * </span>
                                                            </label>
                                                            <input
                                                                name="zip"
                                                                type="text" defaultValue={values.zip} value={values.zip}
                                                                className={`form-control  ${(values.error.zip.length > 0) ? 'error' : ''}`}
                                                                id="zip" maxLength="10"
                                                                placeholder="Zip" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />
                                                            {values.error.zip.length > 1 && (
                                                                <span className="text-red">{values.error.zip}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="first name" className="form-label m-b-10">Country<span className="text-red"> * </span></label>
                                                            <Select
                                                                options={gContext.countriesList} value={values.country}
                                                                className={`form-control  padding-select ${(values.error.country.length > 0) ? 'error' : ''}`}

                                                                border={false} onChange={(e) => handleSelectChange(e, 'country')}
                                                            />{values.error.country.length > 1 && (
                                                                <span className="text-red">{values.error.country}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="first name" className="form-label m-b-10">Time Zone<span className="text-red"> * </span></label>
                                                            <span className="d-flex"><Select
                                                                value={values.timezone}
                                                                className={`form-control  padding-select ${(values.error.timezone.length > 0) ? 'error' : ''}`}
                                                                options={timeZone}
                                                                border={false} onChange={(e) => handleSelectChange(e, 'timezone')}
                                                            />
                                                                {loader && <span className=" align-item-center justify-content-center m-l-10 m-t-10"><ClipLoader color="#569d11" size="30px" /> </span>}
                                                            </span>  {values.error.timezone.length > 1 && (
                                                                <span className="text-red">{values.error.timezone}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="phonenumber" className="form-label m-b-10">Phone Number<span className="text-red"> * </span></label>
                                                            <input
                                                                name="phone"
                                                                type="text" defaultValue={values.phone} maxLength="15"
                                                                className={`form-control   ${(values.error.phone.length > 0) ? 'error' : ''}`}
                                                                id="phone"
                                                                placeholder="Phone Number" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                            />
                                                            {values.error.phone.length > 1 && (
                                                                <span className="text-red">{values.error.phone}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                            <label for="Languages" className="form-label m-b-10">Other Languages</label>
                                                            <input
                                                                name="languages"
                                                                type="text"
                                                                className="form-control  " defaultValue={values.languages}
                                                                id="languages"
                                                                placeholder="eg: French,Spanish" onChange={handleFieldsChange}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                <div className="panel-action">
                                                    <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                        updateProfile();

                                                    }}>Save</button>
                                                    <button type="button" onClick={() => { cancelEdit(); }} className="btn btn-dark-outline btn-sm text-black d-flex align-item-center">Cancel</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>}
                                {!loaded &&
                                    <div   className="tab-pane active show" role="tabpanel">




                                        <div className="card panel  p-0">

                                            <div className="card-header">

                                                <div className="client-title movingLoader w-150">
                                                    <h6>&nbsp;</h6>
                                                </div>

                                                <div className="panel-action">
                                                    <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                                    <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>

                                                </div>

                                            </div>

                                            <div className="card-body p-b-0">
                                              

                                                <div className="row">

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input ">
                                                            <label for="first name" className="form-label m-b-10 d-flex movingLoader w-150">  &nbsp;
                                                            </label> 
                                                                                                           <div  >
                                                                <div style={{ height: '100px', width: '100px' }} className=" movingLoader w-full">
                                                                    &nbsp;
                                                                </div></div> 

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input ">
                                                            <label for="first name" className="form-label m-b-10 d-flex movingLoader w-150">  &nbsp;
                                                            </label>



                                                            <div  >
                                                                <div style={{ height: '100px', width: '100px' }} className=" movingLoader w-full">
                                                                    &nbsp;
                                                                </div></div>
                                                                 

                                                        </div>
                                                    </div>


                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">
                                                             
                                                            <input name="fName"
                                                               
                                                                className={`form-control   movingLoader }`}
                                                                
                                                            /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="forn-group-input">

                                                            <input name="fName"

                                                                className={`form-control   movingLoader }`}

                                                            />
                                                        </div>
                                                    </div>

                                                   
                                             

                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                <div className="panel-action">
                                                    <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                                    <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>
 </div>
                                            </div>

                                        </div>
                                    </div>}

                            </> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 2)) ? <> <ExpertProfessional gotoNext={() => {  
                                  if (gContext.userInfo.userID && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].title) {setCurrentPage(3); gotoPage('12'); navigate("/profile", {replace: true }); }
                                         }} /> </> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 5)) ? <> <Education /> </> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 3)) ? <> <Certificate /> </> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 4)) ? <> <Instructions /></> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 6)) ? <> <Experience /> </> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 8)) ? <> <Testimonials /> </> : ''}
                            {(gContext.userInfo.userType === "1" && (currentPage == 7)) ? <> <ExpertSocial /> </> : ''}






                            <div id="certificate" className="tab-pane d-none" role="tabpanel">
                                <div className="card panel  p-0">

                                    <div className="card-header">

                                        <div className="client-title">
                                            <h6>CERTIFICATE INFORMATION</h6>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Name<span className="text-red"> * </span></th>
                                                        <th>Digital Link</th>
                                                        <th className="w-20"></th>
                                                        <th className="w-150"><div className="d-flex align-items-center">File Upload<span className="text-theme m-icon material-icons m-l-5" data-bs-toggle="tooltip" data-bs-original-title="Hooray!">info</span></div></th>
                                                        <th>Expiry Date</th>
                                                        <th className="w-50"></th>
                                                        <th className="w-50"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SpringInfoServices" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="Digital Link" />
                                                        </td>
                                                        <td>
                                                            <span>OR</span>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-dark-outline text-black file-btn" href="#">
                                                                <span className="m-icon material-icons m-r-5">visibility</span>View File
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SpringInfoServices" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="www.google.com" />
                                                        </td>
                                                        <td>
                                                            <span>OR</span>
                                                        </td>
                                                        <td>
                                                            <div className="browse-btn">
                                                                <button type="button" className="btn btn-dark-outline text-black file-btn d-flex align-item-center" href="#">
                                                                    <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                                </button>
                                                                <input type="file" className="form-control browse-file" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SpringInfoServices" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="Digital Link" />
                                                        </td>
                                                        <td>
                                                            <span>OR</span>
                                                        </td>
                                                        <td>
                                                            <div className="browse-btn">
                                                                <button type="button" className="btn btn-dark-outline text-black file-btn d-flex align-item-center" href="#">
                                                                    <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                                </button>
                                                                <input type="file" className="form-control browse-file" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="add-more">
                                            <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div id="education" className="tab-pane" role="tabpanel">
                                <div className="card panel  p-0">

                                    <div className="card-header">

                                        <div className="client-title">
                                            <h6>EDUCATION INFORMATION</h6>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Degree</th>
                                                        <th>Major</th>
                                                        <th>School/University</th>
                                                        <th>Country</th>
                                                        <th>From Date</th>
                                                        <th>To Date</th>
                                                        <th className="w-50"></th>
                                                        <th className="w-50"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>Bachelors</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="eee" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="PSG" />
                                                        </td>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>India</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>Masters</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="ece" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SVNIT" />
                                                        </td>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>India</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>Doctorate</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="ece" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="PU" />
                                                        </td>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>India</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="add-more">
                                            <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div id="experience" className="tab-pane" role="tabpanel">
                                <div className="card panel  p-0">

                                    <div className="card-header">

                                        <div className="client-title">
                                            <h6>EXPERIENCE INFORMATION</h6>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Company</th>
                                                        <th>Role</th>
                                                        <th className="w-50">Current</th>
                                                        <th>From Date</th>
                                                        <th>To Date</th>
                                                        <th className="w-50"></th>
                                                        <th className="w-50"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="MS" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SE" />
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="springinfoservices" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SE" />
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="springinfoservices1 " />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="SE" />
                                                        </td>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <input type="date" className="form-control" placeholder="23/08/2023" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="add-more">
                                            <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div id="socials" className="tab-pane" role="tabpanel">
                                <div className="card panel  p-0">

                                    <div className="card-header">

                                        <div className="client-title">
                                            <h6>SOCIAL INFORMATION</h6>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Social Provider</th>
                                                        <th>Social Link</th>
                                                        <th className="w-50"></th>
                                                        <th className="w-50"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>Facebook</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="Digital link" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">save</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">close</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <select className="form-select">
                                                                <option>Select...</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="Enter Link" />
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">save</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">close</span></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="add-more">
                                            <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div id="testimonials" className="tab-pane" role="tabpanel">
                                <div className="card panel  p-0">

                                    <div className="card-header">

                                        <div className="client-title">
                                            <h6>TESTIMONIALS INFORMATION</h6>
                                        </div>

                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Name<span className="text-red"> * </span></th>
                                                        <th>Digital Link</th>
                                                        <th className="w-20"></th>
                                                        <th className="w-150"><div className="d-flex align-items-center">File Upload<span className="text-theme m-icon material-icons m-l-5" data-bs-toggle="tooltip" data-bs-original-title="Hooray!">info</span></div></th>
                                                        <th className="w-50"></th>
                                                        <th className="w-50"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="test 1" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="Digital Link" />
                                                        </td>
                                                        <td>
                                                            <span>OR</span>
                                                        </td>
                                                        <td>
                                                            <div className="browse-btn">
                                                                <button type="button" className="btn btn-dark-outline text-black file-btn d-flex align-item-center" href="#">
                                                                    <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                                </button>
                                                                <input type="file" className="form-control browse-file" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="test" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="www.google.com" />
                                                        </td>
                                                        <td>
                                                            <span>OR</span>
                                                        </td>
                                                        <td>
                                                            <div className="browse-btn">
                                                                <button type="button" className="btn btn-dark-outline text-black file-btn d-flex align-item-center" href="#">
                                                                    <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                                </button>
                                                                <input type="file" className="form-control browse-file" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="test 2" />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder="Digital Link" />
                                                        </td>
                                                        <td>
                                                            <span>OR</span>
                                                        </td>
                                                        <td>
                                                            <div className="browse-btn">
                                                                <button type="button" className="btn btn-dark-outline text-black file-btn d-flex align-item-center" href="#">
                                                                    <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                                </button>
                                                                <input type="file" className="form-control browse-file" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">edit</span></a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="add-more">
                                            <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </div>


             
        </>
    );
};

export default Profile;
