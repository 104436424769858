export const addDays = (days) => {
  const date = new Date(Date.now());
  date.setDate(date.getDate() + days);
  return date;
};
export const addDateDays = (date, days) => {
    const newDate = new Date(date?.toString()?.split("T")[0]);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};
export const getMonthYear = (data) => {
    const date = new Date(data?.toString()?.split("T")[0]);
    const months = [
        "Jan", "Feb",
        "Mar", "Apr", "May",
        "Jun", "Jul", "Aug",
        "Sep", "Oct",
        "Nov", "Dec"
    ];
  
    return months[date.getMonth()] + " " + date.getFullYear();
}
export const getDateMonthYear = (data) => {
    const date = data?.toString()?.split("T")[0] ? new Date(data?.toString()?.split("T")[0]) : new Date(data);
    const months = [
        "Jan", "Feb",
        "Mar", "Apr", "May",
        "Jun", "Jul", "Aug",
        "Sep", "Oct",
        "Nov", "Dec"
    ];
    try {
        if (data?.toString()?.split("-").length > 1)
            return data?.toString()?.split("-")[2].split("T")[0] + ' ' + months[date.getMonth()] + " " + date.getFullYear();
        else
            return date.getDate() + ' ' + months[date.getMonth()] + " " + date.getFullYear();
    } catch (e) {
        return date.getDate() + ' ' + months[date.getMonth()] + " " + date.getFullYear();
    }
}
export const getMonthDateYear = (data) => {

    const date = data?.toString()?.split("T")[0] ? new Date(data?.toString()?.split("T")[0]) : new Date(data) ;
    const months = [
        "Jan", "Feb",
        "Mar", "Apr", "May",
        "Jun", "Jul", "Aug",
        "Sep", "Oct",
        "Nov", "Dec"
    ];

    try {
        if (data?.toString()?.split("-").length > 1)
            return months[date.getMonth()] + ' ' + data?.toString()?.split("-")[2].split("T")[0] + ", " + date.getFullYear();
        else
            return months[date.getMonth()] + ' ' + date.getDate() + ", " + date.getFullYear();
    } catch (e) {
        return months[date.getMonth()] + ' ' + date.getDate() + ", " + date.getFullYear();
    }
}

export const sinceDate =
    (d1, d2) => {
        let diffInMs; let seconds; let date1; let date2; let posteddate; let minutes; let hours; let days; let month; let year; let yeardiff;
        let monthdiff;

        date1 = new Date(d1);
        date2 = new Date(d2);
        diffInMs = Math.abs(date2 - date1);
        seconds = Math.round(diffInMs / 1000);
        minutes = Math.round(diffInMs / (1000 * 60));
        hours = Math.round(diffInMs / (1000 * 60 * 60));
        days = Math.round(diffInMs / (1000 * 60 * 60 * 24));

        month = Math.round(days / 30.4167);
        yeardiff = (diffInMs / (1000 * 60 * 60 * 24));
        year = (Math.floor(yeardiff / 365.25));

        if (seconds >= 60) {
            posteddate = minutes + " minutes ago";
        }

        else if (seconds < 60 && seconds > 1) {
            posteddate = seconds + " seconds ago";
        }
        else if (seconds == 1) {
            posteddate = seconds + " second ago";
        }
        else if (seconds == '') {
            posteddate = "a second ago";
        }

        if (minutes >= 60) {
            posteddate = hours + " hours ago";
        }
        else if (minutes < 60 && minutes > 1) {
            posteddate = minutes + " minutes ago";
        }
        else if (minutes == 1) {
            posteddate = minutes + " minute ago";
        }

        if (hours >= 24) {

            if (days >= 30) {

                if (month >= 12) {

                    if (year > 1) {
                        year = posteddate = year + " years ago";
                    }
                    else if (year < 0) {
                        month = posteddate = month + " months ago";
                    }
                    else if (year == 1) {
                        year = posteddate = year + " year ago";
                    }


                }
                else if (month < 12 && month > 1) {
                    posteddate = month + " months ago";
                }
                else if (month == 1) {
                    posteddate = month + " month ago";
                }


            }
            else if (days < 30 && days > 1) {
                posteddate = days + " days ago";
            }
            else if (days == 1) {
                posteddate = days + " day ago";
            }
        }
        else if (hours < 24 && hours > 1) {
            posteddate = hours + " hours ago";
        }
        else if (hours == 1) {
            posteddate = hours + " hour ago";
        }
        return posteddate;

    }