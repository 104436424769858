import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { UserTrxnHistory } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Message from "../../components/Message";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import ReactCountryFlag from "react-country-flag";


export default function PaymentHistory(props) {

    
    const [paymentHistory, setPaymentHistory] = React.useState([]);
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [loaded, setLoaded] = useState(false);

    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(paymentHistory, page, gContext.paginationSize);
 

    async function GetPaymentHistory() {
        try {
            if (gContext.userInfo.userID) {
                gContext.setLoaderModalVisible(true);


                const result = await UserTrxnHistory(gContext.userInfo.userID, gContext.userInfo.userType);

                if (result.data.userTrxnHistory.responseData.code == "200") {
                    
                    setPaymentHistory(JSON.parse(result.data.userTrxnHistory.responseData.message));
                }
                gContext.setLoaderModalVisible(false);
            }
}          
           
        catch (e) {
            console.log("error",e);
            gContext.setLoaderModalVisible(false);
        }
    }
     
    useEffect(() => {
        GetPaymentHistory();
    }, [gContext.userInfo]);
    return (
        <>
         

            {(paymentHistory && paymentHistory.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No Transaction History found</span>
            </div>}
            {(paymentHistory && paymentHistory.length > 0) && <>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th

                                    className="  min-lg-cell"
                                >
                                    ID
                                </th>
                                <th

                                    className="  min-lg-cell"
                                >
                                    Transaction ID
                                </th>
                                {gContext.userInfo.userType == "1" && <th  


                             >
                                    Client
                                </th>}
                                {gContext.userInfo.userType == "2" && <th
                                    

                                >
                                    Expert
                                </th>}
                                <th className=" min-lg-cell"


                                >
                                     Amount
                                </th>
                                {gContext.userInfo.userType =="1" &&
                                    <th className=" min-lg-cell d-none">
                                         Charges
                                    </th>}
                                <th
                                    className=" min-md-cell "
                                >
                                    Status
                                </th>
                                
                                <th
                                    className=" min-md-cell"
                                >
                                    Cancellation  
                                </th>
                                <th
                                    className=" min-md-cell"
                                >
                                    Transaction Date
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {slice && slice.map((data, index) => {
                               
                                return (
                                    <tr key={index}>
                                       
                                       
                                       <td>
                                                <span className="d-flex justify-content-left align-items-left">
                                                <span className="pointer" data-toggle="tooltip" title={data.milestone_task} onClick={(e) => { window.location.href="/contract/" + data.contract_guid; }}>{data.milestoneid}</span>
                                                
                                                </span>
                                               
                                                     
                                            </td>
                                       
                                        <td>
                                            {data.trxn_id}
                                        </td>
                                       
                                        {gContext.userInfo.userType == "1" && <td>

                                            <div className="d-flex justify-content-center align-items-center" onClick={(e) => { window.location.href = "/client/" + data.client_sub; }} >
                                                <div className="info-meta company pointer"   >
                                                    <img className="m-r-10 " src={s3URL + data.client_logo} alt={data.client_firstname + " " + data.client_lastname} onError={(img) => { img.target.src = '../images/user-default.png'; }} />
                                                    <span className="one-line text-black ">{data.client_firstname + " " + data.client_lastname}</span>
                                                
                                                </div>


                                            </div>

                                        </td>}
                                        {gContext.userInfo.userType == "2" && <td>

                                            <div className="d-flex justify-content-center align-items-center" onClick={(e) => { window.location.href = "/expert/" + data.expert_sub; }} >
                                                <div className="info-meta company pointer"   >
                                                    <img className="m-r-10 " src={s3URL + data.expert_avatar} alt={data.expert_firstname + " " + data.expert_lastname} onError={(img) => { img.target.src = '../images/user-default.png'; }} />
                                                    <span className="one-line text-black ">{data.expert_firstname + " " + data.expert_lastname}</span>
                                                   
                                                </div>


                                            </div>

                                        </td>}
                                         
                                        <td>  ${(data.amount) ? new Intl.NumberFormat('en-US').format(parseFloat(data.amount)) : ''}</td>
                                        {gContext.userInfo.userType == "1" &&
                                            <td className="d-none">  ${(data.amount) ? new Intl.NumberFormat('en-US').format((parseFloat(data.amount) * parseFloat(process.env.REACT_APP_EXPERTCHARGES)).toFixed(2)) : ''}</td>}
                                        <td className="text-capi">
                                            {data.status ? (data.status.toLowerCase() == "succeeded" ? <> <div className="d-flex align-item-center  justify-content-center">Payment Success
                                                {gContext.userInfo.userType == "1" && <OverlayTrigger trigger="hover" placement="auto"
                                                    overlay={<Popover className="   " >

                                                        <div className="tooltip-header bg-gray">
                                                            <h5>Information</h5>
                                                        </div>
                                                        <div className="tooltip-meta">
                                                            <p>
                                                                Payment will be received in 6-8 business days from Transaction date.
                                                            </p>
                                                        </div>
                                                    </Popover>} >
                                                    <span className="text-theme m-icon material-icons m-l-5 f-24 pointer " >info</span>
                                                </OverlayTrigger>}</div></> : <>{data.status}</>

                                            ) : "-"}
                                        </td>
                                        <td>
                                            {data.cancellation_reason ? data.cancellation_reason : "-"}
                                        </td>
                                        <td>{data.trxn_created_datetime ? getMonthDateYear(data.trxn_created_datetime) : ""}</td>


                                       
                                    </tr>)
                            })}</tbody>
                    </table>


                  

                
                </div>
                {paymentHistory && paymentHistory.length > 5 &&
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                 </>}


        </>
    );
};